import { faCircle } from "@awesome.me/kit-af809b8b43/icons/classic/light";
import { faCircleCheck } from "@awesome.me/kit-af809b8b43/icons/classic/solid";
import { Group, UnstyledButton } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, generatePath } from "react-router";

import type { SoftwareWithPublishedDocumentsDto } from "@/api/openapi";
import type { Software } from "types";
import { paths } from "~/paths";

type SoftwareGroupProps = {
  list: (SoftwareWithPublishedDocumentsDto | Software)[];
  showStatus?: boolean;
  onClick?: (name: string) => void;
};

export function SoftwareGroup({
  list,
  showStatus = false,
  onClick = () => {},
}: SoftwareGroupProps) {
  if (!list.length) {
    return null;
  }

  const publishedDocUrlTitleMap =
    list.reduce(
      (acc, software) => {
        if (software.documents?.[0]?.urlTitle) {
          acc[software.id] = generatePath(paths.documentation.viewer, {
            urlSlug: software.documents[0].urlTitle,
          });
        }
        return acc;
      },
      {} as Record<string, string>,
    ) ?? {};

  return (
    <Group className="gap-2">
      {list.map(({ website, icon, name, id, documents }) => {
        const urlTitle = publishedDocUrlTitleMap[id];
        const link = urlTitle ? urlTitle : website;

        if (link) {
          return (
            <UnstyledButton
              key={id}
              component={Link}
              to={link}
              target={urlTitle ? "_self" : "_blank"}
              rel="noopener noreferrer" // open external site in new tab
              className="flex flex-nowrap items-center gap-2 rounded-[--mantine-radius-default] border border-[--mantine-color-gray-4] border-solid p-2"
              onClick={() => onClick(name)}
            >
              {icon && <img src={icon} alt={name} className="size-5" />}
              <span className="line-clamp-1">{name}</span>
              {showStatus &&
                (documents?.length ? (
                  <FontAwesomeIcon
                    className="text-[--mantine-color-green-filled]"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-[--mantine-color-gray-4]"
                    icon={faCircle}
                  />
                ))}
            </UnstyledButton>
          );
        }

        // If the software has no documentation or website, most likely it's a
        // custom software that experts have added.
        return (
          <span
            key={id}
            className="rounded-[--mantine-radius-default] border border-[--mantine-color-gray-4] border-solid p-2"
          >
            <span className="line-clamp-1">{name}</span>
          </span>
        );
      })}
    </Group>
  );
}
