import { OpenAPIClientAxios } from "openapi-client-axios";

import definition from "./openapi-runtime.json";
import type { Client } from "./openapi.d.ts";

export const api = new OpenAPIClientAxios({
  definition,
  axiosConfigDefaults: {
    baseURL: import.meta.env.VITE_API_BASE_URL,
    withCredentials: true,
  },
});

export const httpClient = api.initSync<Client>();
