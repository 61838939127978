import type { QueryClient } from "@tanstack/react-query";
import axios, { type AxiosError } from "axios";

import { api } from "@/api/client";
import { Amplitude } from "@/third-party/amplitude";
import cookie from "js-cookie";

import type { SignInType } from "types";

const ACCESS_TOKEN_COOKIE_NAME = "sl_at";
const REFRESH_TOKEN_COOKIE_NAME = "sl_rt";

type JWT = {
  accessToken: string;
  refreshToken: string;
};

type SignInProps = {
  /**
   * For all types of tokens
   */
  token?: string;
  /**
   * For some cases the accessToken is explicitly required.
   */
  accessToken?: string;
  signInType: SignInType;
};

let axios401SignoutInProgress = false;
let axios401Interceptor: number | undefined = undefined;
let openapiAxios401Interceptor: number | undefined = undefined;

export function setupAxios401Interceptor(queryClient: QueryClient) {
  if (axios401Interceptor) {
    return;
  }
  try {
    const authErrorHandler = (error: AxiosError) => {
      if (
        error?.response?.status === 401 &&
        error?.response?.config?.url !== "/auth/logout" &&
        !axios401SignoutInProgress
      ) {
        axios401SignoutInProgress = true;
        signOut(queryClient);
        axios401SignoutInProgress = false;
      }
      throw error;
    };

    openapiAxios401Interceptor = api.client.interceptors.response.use(
      (response) => {
        return response;
      },
      authErrorHandler,
    );

    axios401Interceptor = axios.interceptors.response.use((response) => {
      return response;
    }, authErrorHandler);
  } catch (_err) {}
}

function clearAxios401Interceptor() {
  if (axios401Interceptor) {
    axios.interceptors.response.eject(axios401Interceptor);
  }

  if (openapiAxios401Interceptor) {
    api.client.interceptors.response.eject(openapiAxios401Interceptor);
  }
}

export async function SSOSignIn(signInProps: SignInProps) {
  let ssoPath = "";

  switch (signInProps.signInType) {
    case "GOOGLE_SSO":
      ssoPath = "/auth/sso/google";
      break;
    case "MICROSOFT_SSO":
      ssoPath = "/auth/sso/microsoft";
      break;
    default:
      break;
  }

  try {
    const { data } = await axios.post<JWT>(ssoPath, {
      token: signInProps.token || "",
      accessToken: signInProps.accessToken || "",
    });

    // Set the cookies to be secure only in production.
    // Secure cookies don't work in development because the server may not be using HTTPS.
    cookie.set(ACCESS_TOKEN_COOKIE_NAME, data.accessToken, {
      expires: 70,
      sameSite: "strict",
      secure: import.meta.env.PROD,
    });
    cookie.set(REFRESH_TOKEN_COOKIE_NAME, data.refreshToken, {
      expires: 70,
      sameSite: "strict",
      secure: import.meta.env.PROD,
    });
  } catch (error) {
    // Typecast the error to AxiosError
    const errorObj = error as AxiosError<{
      message: string;
      statusCode: number;
    }>;
    throw new Error(errorObj.response?.data?.message);
  }
}

export function signOut(queryClient: QueryClient) {
  // There is no need to handle if the request fails because the user is logging out anyway.
  axios.post("/auth/logout");

  // Remove cookies
  cookie.remove(ACCESS_TOKEN_COOKIE_NAME);
  cookie.remove(REFRESH_TOKEN_COOKIE_NAME);

  // Remove the Axios interceptors
  clearAxios401Interceptor();

  // Clear the Amplitude user
  Amplitude.reset();

  // Clear the React Query cache
  queryClient.clear();
  window.location.reload();

  return "Sign-out successful.";
}

export function getAccessToken() {
  return cookie.get(ACCESS_TOKEN_COOKIE_NAME);
}
