import { useCallback } from "react";

import { useSearchParams } from "react-router";
import { paths } from "~/paths";

type ProfilePageModalName =
  | "myProfile"
  | "introVideo"
  | "software"
  | "technicalAreas"
  | "industry"
  | "itSetup"
  | "companyInfo";

export function useProfilePageSearchParams() {
  const [searchParams] = useSearchParams();

  const isModalOpen = (modalName: ProfilePageModalName) => {
    const ret = searchParams.get("profileModal") === modalName;

    return ret;
  };

  const generateModalOpenLink = (modalName: ProfilePageModalName) => {
    const res = `${paths.profile.root}?${new URLSearchParams({
      profileModal: modalName,
    }).toString()}`;

    return res;
  };

  return { isModalOpen, generateModalOpenLink };
}

export function useExpertFullProfileSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const expertProfileParam = "expertProfile";
  const bringExpertProfileToFront = "toFront";

  const getExpertIdentifier = () => {
    return searchParams.get(expertProfileParam) || "";
  };

  const isExpertProfileToFront = searchParams.get(bringExpertProfileToFront);

  const setExpertIdentifier = (identifier: string, toFront?: boolean) => {
    searchParams.set(expertProfileParam, identifier);
    if (toFront) {
      searchParams.set(bringExpertProfileToFront, "true");
    }
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const clearExpertIdentifier = () => {
    searchParams.delete(expertProfileParam);
    searchParams.delete(bringExpertProfileToFront);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  return {
    getExpertIdentifier,
    setExpertIdentifier,
    isExpertProfileToFront,
    clearExpertIdentifier,
  };
}

export function useBookingSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const bookSessionParam = "bookSession";
  const bookSessionValue = "start";

  const generateStartBookingLink = (url: string) => {
    const newUrl = new URL(url);
    newUrl.searchParams.set(bookSessionParam, bookSessionValue);
    return newUrl.toString();
  };

  const setStartBooking = () => {
    searchParams.set(bookSessionParam, bookSessionValue);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const clearStartBooking = () => {
    searchParams.delete(bookSessionParam);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const isStartBooking =
    searchParams.get(bookSessionParam) === bookSessionValue;

  return {
    generateStartBookingLink,
    setStartBooking,
    clearStartBooking,
    isStartBooking,
  };
}

export function useSessionSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sessionIdParam = "sessionId";

  const sessionId = searchParams.get(sessionIdParam) || "";

  const setSessionId = (sessionId: string) => {
    searchParams.set(sessionIdParam, sessionId);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const clearSession = () => {
    searchParams.delete(sessionIdParam);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  return { sessionId, setSessionId, clearSession };
}

export function useOAuthSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const oAuthCodeParam = "code";

  const oAuthCode = searchParams.get(oAuthCodeParam) || "";

  const clearOAuthCode = useCallback(() => {
    searchParams.delete(oAuthCodeParam);
    setSearchParams(searchParams, { preventScrollReset: true });
  }, [searchParams, setSearchParams]);

  return { oAuthCode, clearOAuthCode };
}

export function useSignUpSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const signUpParam = "signUp";
  const signUpValue = "true";
  const postSignUpParam = "postSignUp";

  const isSignUpModalOpen = searchParams.get(signUpParam) === signUpValue;
  const postSignUp = searchParams.get(postSignUpParam) || "";

  const openSignUpModal = (params?: { postSignUp: string }) => {
    searchParams.set(signUpParam, signUpValue);
    if (params?.postSignUp) {
      // Validate that the URL is internal to prevent open redirects
      const url = new URL(params.postSignUp, window.location.origin);
      if (url.origin === window.location.origin) {
        searchParams.set(postSignUpParam, params.postSignUp);
      }
    }
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const closeSignUpModal = (params?: { deletePostSignUp: boolean }) => {
    searchParams.delete(signUpParam);
    if (params?.deletePostSignUp ?? true) {
      searchParams.delete(postSignUpParam);
    }
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  return { isSignUpModalOpen, postSignUp, closeSignUpModal, openSignUpModal };
}
