import { useQuery } from "@tanstack/react-query";

import { httpClient } from "@/api/client";
import { expertQueryKey } from "@/hooks/use-expert";

export const technicalAreasQueryKey = {
  technicalAreas: "technicalAreas",
  expertTechnicalAreas: "expertTechnicalAreas",
  expertByIdentifierTechnicalAreas: "expertByIdentifierTechnicalAreas",
};

export const TECHNICAL_AREAS_PATH = "/v1/technical-areas";

// Fetches all the technical areas
export function useTechnicalAreas() {
  return useQuery({
    queryKey: [technicalAreasQueryKey.technicalAreas],
    queryFn: async () => {
      const { data } = await httpClient.paths[TECHNICAL_AREAS_PATH].get();
      return data;
    },
  });
}

export const EXPERT_TECHNICAL_AREAS_PATH = "/v1/expert/technical-areas";

// Fetches the technical areas of the expert (signed-in user)
export function useExpertTechnicalAreas() {
  return useQuery({
    queryKey: [expertQueryKey.expertTechnicalAreas],
    queryFn: async () => {
      const { data } =
        await httpClient.paths[EXPERT_TECHNICAL_AREAS_PATH].get();
      return data;
    },
  });
}

export const EXPERT_BY_IDENTIFIER_TECHNICAL_AREAS_PATH =
  "/v1/experts/{identifier}/technical-areas";

// Fetches the technical areas of the expert with the given identifier
export function useExpertByIdentifierTechnicalAreas({
  identifier = "",
}: { identifier?: string }) {
  return useQuery({
    queryKey: [
      technicalAreasQueryKey.expertByIdentifierTechnicalAreas,
      identifier,
    ],
    queryFn: async () => {
      const { data } = await httpClient.paths[
        EXPERT_BY_IDENTIFIER_TECHNICAL_AREAS_PATH
      ].get({ identifier });
      return data;
    },
    enabled: !!identifier,
  });
}
